import { LoginDO } from "data-model";
import { DateTime } from "luxon";

const stringify = (value: any) =>
  typeof value === "object" && value !== null ? JSON.stringify(value) : value;

const remember = (key: string, value: any) =>
  localStorage.setItem(key, stringify(value));

const discard = (key: string) => localStorage.removeItem(key);

const retrieve = <T>(key: string): T | null => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value!);
  } catch {
    // @ts-expect-error The return above works.
    return value;
  }
};

// Logged-in user, if any

const USER_KEY = "user";
const EXPIRES_AT_KEY = "expiresAt";

export const rememberUser = (user: LoginDO) => remember(USER_KEY, user);

export const discardUser = () => discard(USER_KEY);

export const retrieveUser = () => retrieve<LoginDO>(USER_KEY);

export const forceLogOut = () => {
  discardUser();
  discardExpiresAt();
  discardBranch();
  location.reload();
};

// Session expiry, if any

export const rememberExpiresAt = () =>
  remember(EXPIRES_AT_KEY, DateTime.utc().plus({ weeks: 1 }).valueOf());

export const discardExpiresAt = () => discard(EXPIRES_AT_KEY);

export const retrieveExpiresAt = () => retrieve<number>(EXPIRES_AT_KEY);

// Current branch, if any

const BRANCH_KEY = "branch";

export const rememberBranch = (branch: string) => remember(BRANCH_KEY, branch);

export const discardBranch = () => discard(BRANCH_KEY);

export const retrieveBranch = () => retrieve<string>(BRANCH_KEY);
