import {
  retrieveExpiresAt,
  retrieveUser,
  retrieveBranch,
  discardUser,
  discardBranch,
  discardExpiresAt,
  rememberBranch,
  forceLogOut,
} from "./storage";
import { API_URL, WS_URL } from "./api";
import { http } from "./http";
import { exists } from "./utils";
import { LoginDO, readSEOPageDOs } from "data-model";
import { memDB, MemDBProxy } from "jsongo";
import LightningFS from "@isomorphic-git/lightning-fs";
import {
  FC,
  PropsWithChildren,
  useRef,
  useReducer,
  useEffect,
  Reducer,
  Dispatch,
  createContext,
} from "react";
import {
  fastForward,
  clone,
  checkout,
  fetch as gitFetch,
  setConfig,
  Errors,
} from "isomorphic-git";

// GitHub

export const REPO_OWNER = "caravantours";
export const REPO_NAME = "cvn-main-repo";
export const REPO_DIR = `/${REPO_NAME}`;
export const dir = REPO_DIR; // alias
export const PROD_BRANCH = "public";

// State

interface AppState {
  user: LoginDO | null;
  fs: LightningFS;
  db: MemDBProxy | null; // jsongo (derived from fs)
  branch: string | null; // currently checked out branch
}

// Store

const FS_NAME = "fs"; // in IndexedDB

const AppStore: FC<PropsWithChildren> = ({ children }) => {
  const wsRef = useRef<WebSocket | null>(null);
  const value = useReducer(appReducer, null, initState);
  const [state, dispatch] = value;
  const { fs, user, branch } = state;

  useEffect(() => {
    if (user) {
      const recalcDb = async (fs: LightningFS) => {
        const db = await initJsongo(fs);
        dispatch({ type: "RECALC_DB", db });
      };

      const fastForwardOrClone = async (ref: string, singleBranch = false) => {
        if (await exists(fs, dir)) {
          // Repo has already been cloned.
          // Fetch all branches and merge the current branch
          try {
            await fastForward({ fs, http, dir, ref, singleBranch });
          } catch (e) {
            if (
              e instanceof Errors.FastForwardError ||
              e instanceof Errors.MergeNotSupportedError
            ) {
              console.error(e);
              // git.merge() currently only supports the diff3 algorithm. If it
              // can't resolve the merge conflicts, we have to re-clone the repo.
              const fs = new LightningFS(FS_NAME, { wipe: true });
              await cloneRepo(fs, user, ref);
              await recalcDb(fs);
              dispatch({ type: "RESET_FS", fs });
              return;
            } else {
              throw e;
            }
          }
        } else {
          // Repo dir doesn't exist, clone it
          await cloneRepo(fs, user, branch!);
        }
        await recalcDb(fs);
      };

      (async () => {
        try {
          await fastForwardOrClone(branch!);
        } catch (e: any) {
          console.error(e);
          if (e.name === "HttpError" && e.data.statusCode === 401) {
            forceLogOut();
          } else if (e instanceof Errors.NotFoundError) {
            // We couldn't fetch because the branch was deleted.
            const newBranch = "public";

            await checkout({ fs, dir, ref: newBranch });
            rememberBranch(newBranch);

            await fastForwardOrClone(newBranch);
            dispatch({ type: "CHECKOUT_BRANCH", branch: newBranch });

            alert(
              `${branch} branch was deleted. You are now on ${newBranch} branch`
            );
          } else {
            alert(e.message);
          }
        }
      })();

      wsConnect({
        onOpen: (ws) => {
          wsRef.current = ws;
        },
        onMessage: async (e) => {
          const { event, ref, created, deleted } = JSON.parse(e.data);
          const currentBranch = retrieveBranch()!;

          if (event === "push") {
            if (deleted) {
              if (currentBranch === ref) {
                const newBranch = "public";
                await checkout({ fs, dir, ref: newBranch });
                rememberBranch(newBranch);

                await recalcDb(fs);
                dispatch({ type: "CHECKOUT_BRANCH", branch: newBranch });
                alert(
                  `${ref} branch was deleted. You are now on ${newBranch} branch`
                );
              }
            } else if (created) {
              await gitFetch({ fs, http, dir, ref, singleBranch: true });
            } else {
              try {
                fastForwardOrClone(ref, true);
              } catch (e: any) {
                console.error(e);
                alert(e.message);
              }
            }
          }
        },
      });

      const timeoutId = setTimeout(() => {
        discardUser();
        discardExpiresAt();
        discardBranch();

        dispatch({ type: "LOG_OUT" });

        alert("Your session has expired. Please re-login.");
      }, retrieveExpiresAt()! - Date.now());

      return () => window.clearTimeout(timeoutId);
    } else if (wsRef.current) {
      wsRef.current.close(); // logout
    }
  }, [user]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export { AppStore };

// Lazy initialization

const initState = () => {
  const expiresAt = retrieveExpiresAt();

  let wipe = false;
  if (expiresAt && expiresAt <= Date.now()) {
    discardUser();
    discardExpiresAt();
    discardBranch();
    wipe = true;
  }

  const user = retrieveUser();
  const fs = new LightningFS(FS_NAME, { wipe }); // create or open
  const db = null; // fs could be empty
  const branch = retrieveBranch();

  return { db, fs, user, branch };
};

// Reducer

const appReducer: Reducer<AppState, AppAction> = (state, action) => {
  switch (action.type) {
    case "LOG_OUT":
      return {
        ...state,
        // FIXME DOMException: Lock broken by another request with the 'steal' option.
        fs: new LightningFS(FS_NAME, { wipe: true }),
        user: null,
        db: null,
        branch: null,
      };
    case "LOG_IN": {
      const { user, branch } = action;
      return { ...state, user, branch };
    }
    case "RECALC_DB":
      return { ...state, db: action.db };
    case "CHECKOUT_BRANCH":
      return { ...state, branch: action.branch };
    case "RESET_FS":
      return { ...state, fs: action.fs };
    default:
      return state;
  }
};

// Actions

type AppAction =
  | LogOutAction
  | LogInAction
  | RecalcDbAction
  | CheckoutBranchAction
  | ResetFsAction;

interface LogOutAction {
  type: "LOG_OUT";
}

interface LogInAction {
  type: "LOG_IN";
  user: LoginDO;
  branch: string;
}

interface RecalcDbAction {
  type: "RECALC_DB";
  db: MemDBProxy;
}

interface CheckoutBranchAction {
  type: "CHECKOUT_BRANCH";
  branch: string;
}

interface ResetFsAction {
  type: "RESET_FS";
  fs: LightningFS;
}

// Context

type AppDispatch = Dispatch<AppAction>;

type AppValue = [AppState, AppDispatch];

export const AppContext = createContext<AppValue>([] as any);

// Jsongo

export const initJsongo = async (fs: LightningFS) => {
  const fsp = fs.promises;
  const dirPath = `${dir}/${process.env.JSONGO_DIR}`;
  const db = memDB();

  const filenames = await fsp.readdir(dirPath);

  const promises = filenames.map(async (filename) => {
    const json = await fsp.readFile(`${dirPath}/${filename}`, "utf8");
    const collection = filename.slice(0, filename.indexOf(".json"));
    db[collection].insertMany(JSON.parse(json as string));
  });

  await Promise.all(promises);

  const seoPageDOs = await readSEOPageDOs(fs, dir);
  db.seoPage.insertMany(seoPageDOs);

  return db;
};

// GitHub

const cloneRepo = async (fs: LightningFS, user: LoginDO, ref: string) => {
  const pfs = fs.promises;

  try {
    await clone({
      fs,
      http,
      dir,
      corsProxy: API_URL,
      url: `https://github.com/${REPO_OWNER}/${REPO_NAME}.git`,
      ref,
      depth: 10,
    });

    const identity = {
      "user.name": user.fullName,
      "user.email": user.email,
    };

    for (const [path, value] of Object.entries(identity)) {
      // Purposely sequential to avoid a race condition
      await setConfig({ fs, dir, path, value });
    }
  } catch (e) {
    // .git dir was still created, delete the repo dir
    await pfs.unlink(dir);
    throw e;
  }
};

// WebSockets

const MAX_TIMEOUT_MS = 1000 * 60 * 2; // 2 min

const wsConnect = ({
  onOpen,
  onMessage,
  timeoutMs = 1000,
}: {
  onOpen: (ws: WebSocket) => void;
  onMessage: (e: MessageEvent) => void;
  timeoutMs?: number;
}) => {
  const ws = new WebSocket(WS_URL);

  ws.addEventListener("open", () => {
    timeoutMs = 1000;
    onOpen(ws);
  });
  ws.addEventListener("message", onMessage);
  ws.addEventListener("close", (e) => {
    if (e.code === 1005) return; // closed manually
    // Bounded exponential backoff
    if (timeoutMs !== MAX_TIMEOUT_MS) {
      timeoutMs *= 2;
    }
    setTimeout(() => {
      wsConnect({ onOpen, onMessage, timeoutMs });
    }, timeoutMs);
  });

  return ws;
};
