import "./app.css";
import { AppStore, AppContext } from "./Store";
import { Loading, Header, RequiresPermission } from "./components";
import {
  Activity,
  Documents,
  Inventory,
  LazyCaravanSite,
  Login,
  Refunds,
  SEOEditor,
} from "./pages";
import {
  CvnProvider,
  ModalProvider,
  MessageProvider,
  RequiresAuth,
  RequiresGuest,
  fakeManifest,
} from "react-components";
import { permissionsDict } from "data-model";
import { FC, PropsWithChildren, Suspense, useContext } from "react";
import { Link, BrowserRouter, Routes, Route } from "react-router-dom";

const App: FC = () => (
  <AppStore>
    <Suspense fallback={<Loading />}>
      <Main />
    </Suspense>
  </AppStore>
);

const Main: FC = () => {
  const [{ fs, user, db }] = useContext(AppContext);
  const isLoggedIn = !!user;

  const context = {
    fs,
    manifest: fakeManifest,
    publicPath: "",
    repoRoot: `/cvn-main-repo`,
    routerLink: Link,
  };

  const permissions = permissionsDict(user, db);
  // Route components that require db will "wait" (return null)
  // until jsongo is initialized. When logged out, db is always null.
  const isReady = isLoggedIn ? !!db : true;

  return (
    <CvnProvider value={context}>
      <MessageProvider>
        <BrowserRouter>
          <ModalProvider>
            <Header />
            <Wait isReady={isReady}>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <RequiresGuest auth={isLoggedIn}>
                      <Login />
                    </RequiresGuest>
                  }
                />
                <Route
                  path="/seo-editor"
                  element={
                    <RequiresPermission
                      auth={isLoggedIn}
                      allowed={permissions.EditSEOPages}
                    >
                      <SEOEditor />
                    </RequiresPermission>
                  }
                />
                <Route
                  path="/refunds"
                  element={
                    <RequiresPermission
                      auth={isLoggedIn}
                      allowed={permissions.ApproveRefund}
                    >
                      <Refunds />
                    </RequiresPermission>
                  }
                />
                <Route
                  path="/inventory"
                  element={
                    <RequiresPermission
                      auth={isLoggedIn}
                      allowed={permissions.ViewInventory}
                    >
                      <Inventory />
                    </RequiresPermission>
                  }
                />
                <Route
                  path="/documents"
                  element={
                    <RequiresPermission
                      auth={isLoggedIn}
                      allowed={permissions.GenerateDocs}
                    >
                      <Documents />
                    </RequiresPermission>
                  }
                />
                <Route
                  path="/activity"
                  element={
                    <RequiresPermission
                      auth={isLoggedIn}
                      allowed={permissions.ViewActivity}
                    >
                      <Activity />
                    </RequiresPermission>
                  }
                />
                {/* The route below will match all URLs starting with / */}
                <Route
                  path="*"
                  element={
                    <RequiresAuth auth={isLoggedIn}>
                      <LazyCaravanSite />
                    </RequiresAuth>
                  }
                />
              </Routes>
            </Wait>
          </ModalProvider>
        </BrowserRouter>
      </MessageProvider>
    </CvnProvider>
  );
};

const Wait: FC<PropsWithChildren<{ isReady: boolean }>> = ({
  isReady,
  children,
}) => {
  if (!isReady) return <Loading />;
  return <>{children}</>;
};

export { App };
