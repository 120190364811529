import { AppContext } from "../Store";
import { API_URL, getDepartureToCancelled } from "../api";
import {
  arrUnique,
  departureDocumentName,
  departureDocumentTypes,
  JsongoDeparturePairsRes,
  seriesCodeFrom,
  SeriesDO,
  seriesIdFrom,
  TourYearDO,
} from "data-model";
import { ExternalLink, SVG, Select } from "react-components";
import { useContext, useEffect, useState } from "react";

const Documents = () => {
  const [{ db }] = useContext(AppContext);
  if (!db) throw new Error("db is uninitialized"); // unreachable

  const [series, setSeries] = useState("RI");

  const allSeries = db.series.docs() as SeriesDO[];
  const uniqueCodes = arrUnique(allSeries.map(seriesCodeFrom).sort());
  const years = getYearsForSeriesCode(allSeries, series);
  const [year, setYear] = useState(currentOrMinYear(years));

  const seriesId = seriesIdFrom(series, year);
  const seriesDO = db.series.findByIdOrFail(seriesId) as SeriesDO;
  const tourYear = db.tourYear.findByIdOrFail(
    seriesDO.tourYear_id
  ) as TourYearDO;

  const [departurePairs, setDeparturePairs] = useState<JsongoDeparturePairsRes>(
    []
  );
  const [departedAt, setDepartedAt] = useState(
    firstNonCancelled(departurePairs)
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleSeriesChange = async (newSeries: string, newYear: number) => {
    setIsLoading(true);
    const pairs = await getDepartureToCancelled(newSeries, newYear);
    const departedAt = firstNonCancelled(pairs);
    setIsLoading(false);
    setSeries(newSeries);
    setYear(newYear);
    setDeparturePairs(pairs);
    setDepartedAt(departedAt);
  };

  useEffect(() => {
    handleSeriesChange(series, year);
  }, []);

  return (
    <main className="padding-top-2 padding-x-4 padding-bottom-4">
      <div className="is-flex is-align-items-center">
        <label htmlFor="series" className="margin-right-2">
          Series:
        </label>
        <Select
          id="series"
          value={series}
          onChange={(e) => {
            const newSeries = e.currentTarget.value;
            const newYear = currentOrMinYear(
              getYearsForSeriesCode(allSeries, newSeries)
            );
            handleSeriesChange(newSeries, newYear);
          }}
        >
          {uniqueCodes.map((code) => (
            <option key={code}>{code}</option>
          ))}
        </Select>
        <label htmlFor="year" className="margin-left-3 margin-right-2">
          Year:
        </label>
        <Select
          id="year"
          value={year}
          onChange={(e) => handleSeriesChange(series, +e.currentTarget.value)}
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
        <label htmlFor="departedAt" className="margin-left-3 margin-right-2">
          Departure:
        </label>
        <Select
          id="departedAt"
          value={departedAt}
          onChange={(e) => setDepartedAt(e.currentTarget.value)}
          disabled={isLoading}
        >
          {departurePairs.map(([departedAt, isCancelled]) => (
            <option key={departedAt} value={departedAt} disabled={isCancelled}>
              {departedAt}
            </option>
          ))}
        </Select>
        {isLoading && (
          <SVG
            path="site/icon/spinner"
            alt="Spinner"
            height={20}
            className="margin-left-3"
          />
        )}
      </div>

      {departedAt && (
        <ul className="has-list-style-type-none margin-top-3 margin-bottom-0 is-paddingless">
          {departureDocumentTypes.map((type) => {
            if (type === "passport-info" && !tourYear.requiresPassport) {
              return null;
            }

            return (
              <li key={type} className="margin-bottom-2">
                <ExternalLink
                  href={`${API_URL}/documents/${type}?series=${series}&departedAt=${departedAt}`}
                  target="_blank"
                >
                  {departureDocumentName[type]}
                </ExternalLink>
              </li>
            );
          })}
        </ul>
      )}
    </main>
  );
};

export { Documents };

const getYearsForSeriesCode = (allSeries: SeriesDO[], seriesCode: string) =>
  allSeries
    .filter((s) => s._id.startsWith(seriesCode))
    .map((s) => s.tourYear_id.year);

const currentOrMinYear = (years: number[]) => {
  const thisYear = new Date().getFullYear();
  return years.includes(thisYear) ? thisYear : Math.min(...years);
};

const firstNonCancelled = (pairs: JsongoDeparturePairsRes) => {
  const firstNonCancelledIdx = pairs.findIndex(
    ([, isCancelled]) => !isCancelled
  );
  return firstNonCancelledIdx !== -1 ? pairs[firstNonCancelledIdx][0] : "";
};
