import { AppContext, dir, initJsongo, REPO_OWNER, REPO_NAME } from "../Store";
import {
  discardUser,
  discardExpiresAt,
  discardBranch,
  rememberBranch,
} from "../storage";
import { logOut } from "../api";
import { http } from "../http";
import { currentCommit } from "../utils";
import { permissionsDict } from "data-model";
import { useMessage, SVG, Link as RCLink } from "react-components";
import { FC, useContext, useState, useEffect, ChangeEvent } from "react";
import { useNavigate, Link } from "react-router-dom";
import { listBranches, checkout, fastForward } from "isomorphic-git";

const Header: FC = () => {
  const [{ db, user }, dispatch] = useContext(AppContext);
  const navigate = useNavigate();
  const [, setMessage] = useMessage();

  const handleLogout = async () => {
    try {
      await logOut();

      discardUser();
      discardExpiresAt();
      discardBranch();

      dispatch({ type: "LOG_OUT" });

      navigate("/login");
    } catch (e: any) {
      setMessage({ children: e.message });
      console.error(e);
    }
  };

  return (
    <header className="padding-2 is-flex is-align-items-center is-justify-content-space-between">
      <Link to="/">
        <SVG path="site/logo/caravan/blue" alt="Caravan" height={27} />
      </Link>
      {user ? (
        <>
          {db && <AuthNavbar />}
          <button className="button is-medium" onClick={handleLogout}>
            Log out
          </button>
        </>
      ) : (
        <Link to="/login" className="is-ghost">
          <strong>Log In</strong>
        </Link>
      )}
    </header>
  );
};

const AuthNavbar: FC = () => {
  const [{ fs, db, user, branch }, dispatch] = useContext(AppContext);
  const [isBusy, setIsBusy] = useState(false);
  const [branches, setBranches] = useState([branch!]);
  const [commit, setCommit] = useState("");
  const permissions = permissionsDict(user, db);
  const [, setMessage] = useMessage();

  useEffect(() => {
    (async () => {
      const branches = await listBranches({ fs, dir, remote: "origin" });
      const commit = await currentCommit({ fs, dir });

      setBranches(branches.filter((b) => b !== "HEAD"));
      setCommit(commit);
    })();
    // git pull returns void, so we can't subscribe to git-specific changes.
    // Also, fs is mutated directly, so [fs] won't work. Instead we use [db]
  }, [db]);

  const handleCheckout = async (e: ChangeEvent<HTMLSelectElement>) => {
    try {
      setIsBusy(true);

      const newBranch = e.currentTarget.value;
      await checkout({ fs, dir, ref: newBranch }); // FIXME missing folders/files
      // Although we run git pull on mount, it only merges the current branch.
      // This new branch is already fetched but still potentially unmerged.
      await fastForward({ fs, dir, http, singleBranch: true });
      const newCommit = await currentCommit({ fs, dir });

      const db = await initJsongo(fs);
      rememberBranch(newBranch);
      dispatch({ type: "CHECKOUT_BRANCH", branch: newBranch });
      dispatch({ type: "RECALC_DB", db });

      setCommit(newCommit);
    } catch (e: any) {
      setMessage({ children: e.message });
      console.error(e);
    }
    setIsBusy(false);
  };

  return (
    <>
      <nav className="is-flex-1 margin-left-2">
        {permissions.EditSEOPages && (
          <Link to="/seo-editor" className="padding-x-2">
            <strong>SEO Editor</strong>
          </Link>
        )}
        {permissions.ApproveRefund && (
          <Link to="/refunds" className="padding-x-2">
            <strong>Refunds</strong>
          </Link>
        )}
        {permissions.ViewInventory && (
          <Link to="/inventory" className="padding-x-2">
            <strong>Inventory</strong>
          </Link>
        )}
        {permissions.GenerateDocs && (
          <Link to="/documents" className="padding-x-2">
            <strong>Documents</strong>
          </Link>
        )}
        {permissions.ViewActivity && (
          <Link to="/activity" className="padding-x-2">
            <strong>Activity</strong>
          </Link>
        )}
      </nav>
      {isBusy && (
        <SVG
          className="margin-right-1"
          path="/site/icon/spinner"
          alt="Loading"
          height={16}
        />
      )}
      {commit && (
        <RCLink
          external
          className="margin-right-1"
          href={`https://github.com/${REPO_OWNER}/${REPO_NAME}/commit/${commit}`}
        >
          {commit.slice(0, 7)}
        </RCLink>
      )}
      <select
        className="margin-right-1"
        value={branch!}
        onChange={handleCheckout}
        disabled={isBusy}
      >
        {branches.map((branch) => (
          <option key={branch} value={branch}>
            {branch}
          </option>
        ))}
      </select>
    </>
  );
};

export { Header };
