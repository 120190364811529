import { CrossGuard } from "react-components";
import { FC, PropsWithChildren } from "react";

interface RestrictedProps extends PropsWithChildren {
  auth: boolean;
  allowed: boolean;
}

const RequiresPermission: FC<RestrictedProps> = ({
  auth,
  allowed,
  children,
}) => (
  <CrossGuard
    condition1={!auth}
    redirectTo1="/login"
    condition2={!allowed}
    redirectTo2="/"
  >
    {children}
  </CrossGuard>
);

export { RequiresPermission };
