import { AppContext, dir, initJsongo } from "../Store";
import { http } from "../http";
import { exists } from "../utils";
import { SEOPageDO, TourDO, isTourPublic, RouteDO } from "data-model";
import {
  CvnContext,
  convertMDToHTML,
  useModal,
  useMessage,
  SVG,
} from "react-components";
import {
  FC,
  useState,
  useContext,
  MouseEvent,
  FormEvent,
  ChangeEvent,
  ReactNode,
} from "react";
import Editor from "@monaco-editor/react";
import { resetIndex, add, remove, commit, push } from "isomorphic-git";
import clsx from "clsx";

const NEW_PAGE_SENTINEL = "new-seo-page";

const SEOEditor: FC = () => {
  const [{ db }] = useContext(AppContext);
  if (!db) throw new Error("db is uninitialized"); // unreachable
  const [onlyActiveTours, setOnlyActiveTours] = useState(true);
  const [currentId, setCurrentId] = useState("");
  const [currentTourId, setCurrentTourId] = useState("");

  const seoPageDOs = db.seoPage.docs() as SEOPageDO[];
  const tourDOs = db.tour.docs() as TourDO[];
  const tourIds = tourDOs.map(({ _id }) => _id);
  const activeTourIds = tourDOs.filter(isTourPublic).map(({ _id }) => _id);

  const handleCurrentIdSelect = (e: MouseEvent<HTMLLIElement>) =>
    setCurrentId(e.currentTarget.dataset.id!);

  const handleNewPage = (e: MouseEvent<HTMLButtonElement>) => {
    const { tourId, urlPrefix } = e.currentTarget.dataset;
    const _id = `${urlPrefix ? `${urlPrefix}/` : ""}${NEW_PAGE_SENTINEL}`;

    setCurrentId(_id);
    setCurrentTourId(tourId!);
  };

  return (
    <div id="seo">
      <div className="is-flex is-flex-wrap-wrap">
        <aside className="padding-x-2 is-scrollable-y">
          <p className="margin-top-0">
            <input
              type="checkbox"
              checked={onlyActiveTours}
              onChange={() => setOnlyActiveTours((c) => !c)}
              className="margin-right-1"
              id="only-active-tours"
            />
            <label htmlFor="only-active-tours">Only Active Tours</label>
          </p>
          {(onlyActiveTours ? activeTourIds : tourIds).map((tourId) => (
            <ul key={tourId} className="list is-spaced margin-top-0">
              <li
                className={clsx(
                  "is-flex is-align-items-center",
                  `/tour/${tourId}` === currentId && "has-background-celeste"
                )}
              >
                <strong
                  data-id={`/tour/${tourId}`}
                  className="is-flex-1 is-clickable"
                  onClick={handleCurrentIdSelect}
                >
                  {tourId}
                </strong>
                <button
                  className="button is-small"
                  data-tour-id={tourId}
                  data-url-prefix={
                    seoPageDOs
                      .find(({ tour_id }) => tour_id === tourId)
                      ?._id.match(/(.+)\/.+$/)?.[1] // everything up to last slash (i.e. filename)
                  }
                  onClick={handleNewPage}
                >
                  Add
                </button>
              </li>
              {seoPageDOs
                .filter((seoPage) => seoPage.tour_id === tourId)
                .map(({ _id }) => (
                  <li
                    key={_id}
                    data-id={_id}
                    className={clsx(
                      "is-clickable",
                      _id === currentId && "has-background-celeste"
                    )}
                    onClick={handleCurrentIdSelect}
                  >
                    {_id.slice(1)}
                  </li>
                ))}
            </ul>
          ))}
        </aside>
        <EditorForm
          key={currentId}
          currentId={currentId}
          currentTourId={currentTourId}
          onCurrentIdChange={setCurrentId}
          onCurrentTourIdChange={setCurrentTourId}
        />
      </div>
    </div>
  );
};

interface EditorForm {
  currentId: string;
  currentTourId: string;
  onCurrentIdChange: (currentId: string) => void;
  onCurrentTourIdChange: (currentId: string) => void;
}

const monacoOptions = {
  wordWrap: "on",
  minimap: {
    enabled: false,
  },
};

const EditorForm: FC<EditorForm> = ({
  currentId,
  currentTourId,
  onCurrentIdChange,
  onCurrentTourIdChange,
}) => {
  const [{ fs, ...state }, dispatch] = useContext(AppContext);
  const db = state.db!;
  const fsp = fs.promises;
  const { manifest, publicPath } = useContext(CvnContext);
  const setModal = useModal();
  const [, setMessage] = useMessage();

  const isNewPage = currentId.endsWith(NEW_PAGE_SENTINEL);
  const seoPage = (
    isNewPage
      ? { _id: currentId, tour_id: currentTourId }
      : db.seoPage.findOne({ _id: currentId })
  ) as SEOPageDO | undefined;
  const routeDOs = db.route.docs() as RouteDO[];
  const tourPage = routeDOs.find(({ _id }) => _id === currentId);
  const reviewsPage = routeDOs.find(
    ({ _id }) => _id === `${currentId}/reviews`
  );
  const tipsPage = routeDOs.find(
    ({ _id }) => _id === `${currentId}/travel-tips`
  );

  const currentPage = seoPage || tourPage;
  const meta = seoPage || tourPage?.pageWrapperProps;
  const reviewsMeta = reviewsPage?.pageWrapperProps;
  const tipsMeta = tipsPage?.pageWrapperProps;

  const [isDirty, setIsDirty] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [markdown, setMarkdown] = useState(seoPage?.bodyMD);

  const onChange = () => !isDirty && setIsDirty(true);

  const form = useForm({
    prefix: seoPage ? "seo" : "tour",
    defaultUrl: currentPage?._id,
    defaultTitle: meta?.title,
    defaultDescription: meta?.metaDescription,
    defaultKeywords: meta?.metaKeywords,
    titleReadOnly: !!tourPage,
    onChange,
  });

  const reivewsForm = useForm({
    prefix: "reviews",
    label: "Reviews",
    defaultUrl: reviewsPage?._id,
    defaultTitle: reviewsMeta?.title,
    defaultDescription: reviewsMeta?.metaDescription,
    defaultKeywords: reviewsMeta?.metaKeywords,
    titleReadOnly: true,
    onChange,
  });

  const tipsForm = useForm({
    prefix: "tips",
    label: "Travel Tips",
    defaultUrl: tipsPage?._id,
    defaultTitle: tipsMeta?.title,
    defaultDescription: tipsMeta?.metaDescription,
    defaultKeywords: tipsMeta?.metaKeywords,
    titleReadOnly: true,
    onChange,
  });

  const handleDelete = () => {
    const onClose = () => setModal({ isOpen: false, body: null });
    const onDelete = async () => {
      try {
        const filepath = `markdown/seo${seoPage!._id}.md`;

        await fsp.unlink(`${dir}/${filepath}`);
        await resetIndex({ fs, dir, filepath });
        await remove({ fs, dir, filepath });
        await commit({ fs, dir, message: `Delete ${filepath}` });
        await push({ fs, http, dir });

        const newDb = await initJsongo(fs);
        dispatch({ type: "RECALC_DB", db: newDb });
        onCurrentIdChange("");
      } catch (e: any) {
        setMessage({ children: e.message });
        console.error(e);
      }
    };

    function ModalBody() {
      const [isBusy, setIsBusy] = useState(false);
      return (
        <>
          <h2>Delete {seoPage!._id.slice(1)}?</h2>
          <p>You can restore the page in GitHub.</p>
          <div className="is-flex margin-top-4">
            <button className="button is-dark-gray" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button is-yellow margin-left-1 is-flex-1"
              disabled={isBusy}
              onClick={async () => {
                setIsBusy(true);
                await onDelete();
                onClose();
                // Don't call setIsBusy(fase) b/c of a race with onClose()
              }}
            >
              {isBusy ? (
                <SVG path="/site/icon/spinner" alt="Loading" height={16} />
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </>
      );
    }

    setModal({
      isOpen: true,
      body: <ModalBody />,
    });
  };

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      setIsBusy(true);

      const { url, title, description, keywords } = form.values;

      if (seoPage) {
        if (url !== seoPage._id) {
          if (!isNewPage) {
            const filepath = `markdown/seo${seoPage._id}.md`;
            await fsp.unlink(`${dir}/${filepath}`);
            await resetIndex({ fs, dir, filepath });
            await remove({ fs, dir, filepath });
          }

          // Skip first (empty string) and last element (filename)
          const dirSegments = url!.split("/").slice(1, -1);

          for (let idx = 0; idx < dirSegments.length; idx++) {
            // Build up directory segrements as we iterate
            const segment = dirSegments.slice(0, idx + 1).join("/");
            const dirpath = `${dir}/markdown/seo/${segment}`;

            if (!(await exists(fs, dirpath))) {
              await fsp.mkdir(dirpath);
            }
          }
        }

        const filepath = `markdown/seo${url}.md`;
        const frontMatter = `tour_id: ${seoPage.tour_id}\ntitle: ${title}\nmetaDescription: ${description}\nmetaKeywords: ${keywords}`;
        const body = (markdown || "").trim();
        const contents = `---\n${frontMatter}\n---\n\n${body}\n`;

        await fsp.writeFile(`${dir}/${filepath}`, contents);
        await resetIndex({ fs, dir, filepath });
        await add({ fs, dir, filepath });
        await commit({
          fs,
          dir,
          message: `${isNewPage ? "Add" : "Edit"} ${filepath}`,
        });
        await push({ fs, http, dir });
      } else if (tourPage && reviewsPage && tipsPage) {
        // Mutating in place isn't ideal, but we need this for collection.toJson()
        db.route.upsertMany([
          mutateRoute(tourPage, form.values),
          mutateRoute(reviewsPage, reivewsForm.values),
          mutateRoute(tipsPage, tipsForm.values),
        ]);

        const filepath = "jsongo/route.json";
        await fsp.writeFile(`${dir}/${filepath}`, `${db.route.toJson()}\n`);
        await resetIndex({ fs, dir, filepath });
        await add({ fs, dir, filepath });
        await commit({ fs, dir, message: `Edit ${filepath}` });
        await push({ fs, http, dir });
      }

      const newDb = await initJsongo(fs);
      dispatch({ type: "RECALC_DB", db: newDb });

      if (seoPage && url !== seoPage._id) {
        onCurrentIdChange(url);
        if (isNewPage) onCurrentTourIdChange("");
        // Don't call setIsBusy/Dirty(false) due to a race
      } else {
        setIsBusy(false);
        setIsDirty(false);
      }
    } catch (e: any) {
      setMessage({ children: e.message });
      console.error(e);
    }
  };

  return (
    <>
      <section className="is-flex-1 padding-x-2 is-scrollable-y is-unscrollable-x">
        {currentPage ? (
          <form onSubmit={handleSave}>
            <div className="is-grid is-grid-template-columns-1fr-3fr is-row-gap-2 margin-bottom-2">
              {form.form}
              {reviewsPage && reivewsForm.form}
              {tipsPage && tipsForm.form}
            </div>
            {seoPage && (
              <Editor
                height="500px"
                defaultLanguage="markdown"
                defaultValue={markdown}
                onChange={(value) => {
                  onChange();
                  setMarkdown(value);
                }}
                options={monacoOptions}
              />
            )}
            <div className="is-flex margin-top-2">
              <div className="is-flex-1">
                {seoPage && !isNewPage && (
                  <button
                    type="button"
                    className="button is-medium"
                    onClick={handleDelete}
                    disabled={!currentPage}
                  >
                    Delete
                  </button>
                )}
              </div>
              <button
                className="button is-medium"
                disabled={!isDirty || isBusy}
              >
                {isBusy && (
                  <SVG
                    className="padding-right-1"
                    path="/site/icon/spinner"
                    alt="Loading"
                    height={16}
                  />
                )}
                Save Changes
              </button>
            </div>
          </form>
        ) : (
          <p>Please select a page on the left.</p>
        )}
      </section>
      <section className="is-flex-1 padding-x-2 is-scrollable-y">
        {markdown && (
          <div
            dangerouslySetInnerHTML={{
              __html: convertMDToHTML(markdown, publicPath, manifest),
            }}
          />
        )}
      </section>
    </>
  );
};

interface FormProps {
  prefix: string;
  label?: string;
  defaultUrl?: string;
  defaultTitle?: string;
  defaultDescription?: string;
  defaultKeywords?: string;
  titleReadOnly: boolean;
  onChange: () => void;
}

interface FormValues {
  url: string;
  title: string;
  description: string;
  keywords: string;
}

const useForm = ({
  prefix,
  label,
  defaultUrl = "",
  defaultTitle = "",
  defaultDescription = "",
  defaultKeywords = "",
  titleReadOnly,
  onChange,
}: FormProps): { values: FormValues; form: ReactNode } => {
  const [url, setUrl] = useState(defaultUrl);
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [keywords, setKeywords] = useState(defaultKeywords);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange();

    const { value, id } = e.currentTarget;
    switch (id.split("-").pop()) {
      case "url":
        return setUrl(value);
      case "title":
        return setTitle(value);
      case "description":
        return setDescription(value);
      case "keywords":
        return setKeywords(value);
    }
  };

  const form = (
    <>
      {titleReadOnly ? (
        <>
          <span>{label && `${label} `}URL</span>
          <p className="is-marginless">{defaultUrl}</p>
        </>
      ) : (
        <>
          <label htmlFor={`${prefix}-url`}>{label && `${label} `}URL</label>
          <input
            id={`${prefix}-url`}
            value={url}
            onChange={handleChange}
            pattern={`^/.*(?<!${NEW_PAGE_SENTINEL}|/)$`}
            required
          />
        </>
      )}
      <label htmlFor={`${prefix}-title`}>
        {label && `${label} `}Page Title
      </label>
      <input
        id={`${prefix}-title`}
        value={title}
        onChange={handleChange}
        required
      />
      <label htmlFor={`${prefix}-description`}>
        {label && `${label} `}Meta Description
      </label>
      <textarea
        id={`${prefix}-description`}
        rows={5}
        value={description}
        onChange={handleChange}
        required
      />
      <label htmlFor={`${prefix}-keywords`}>
        {label && `${label} `}Meta Keywords
      </label>
      <textarea
        id={`${prefix}-keywords`}
        rows={8}
        value={keywords}
        onChange={handleChange}
        required
      />
    </>
  );

  const values = {
    url,
    title,
    description,
    keywords,
  };

  return { values, form };
};

const mutateRoute = (
  route: RouteDO,
  { title, description, keywords }: FormValues
) => {
  const meta = route.pageWrapperProps!;
  if (title) meta.title = title;
  if (description) meta.metaDescription = description;
  if (keywords) meta.metaKeywords = keywords;
  return route;
};

export { SEOEditor };
