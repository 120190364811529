import { AppContext, PROD_BRANCH } from "../Store";
import { logIn } from "../api";
import { rememberUser, rememberExpiresAt, rememberBranch } from "../storage";
import { ErrorMessage, Input, useErrors } from "react-components";
import { FC, useContext, useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

const Login: FC = () => {
  const [, dispatch] = useContext(AppContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    catchErrors(
      async () => {
        const user = await logIn({ email, password });
        const branch = PROD_BRANCH;

        rememberUser(user);
        rememberExpiresAt();
        rememberBranch(branch);

        dispatch({ type: "LOG_IN", user, branch });

        navigate("/", { replace: true });
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div className="is-flex margin-x-auto">
      <form onSubmit={handleSubmit} style={{ width: 250 }}>
        <div className="margin-bottom-2">
          <Input
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            required
          />
        </div>
        <div className="margin-bottom-2">
          <Input
            id="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            required
          />
        </div>
        <ErrorMessage errors={errors} />
        <button disabled={isLoading} className="button">
          Log in
        </button>
      </form>
    </div>
  );
};

export { Login };
