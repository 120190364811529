import { lazy } from "react";

const LazyCaravanSite = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "caravan-site" */ "./CaravanSite"
    )
);

export { LazyCaravanSite };
