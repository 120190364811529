import LightningFS from "@isomorphic-git/lightning-fs";
import { resolveRef } from "isomorphic-git";
import flatpickr from "flatpickr";
import type { Hook } from "flatpickr/dist/types/options";
import { Dispatch, SetStateAction } from "react";

// FS utils

// https://github.com/isomorphic-git/lightning-fs/issues/21#issuecomment-519230529
export async function exists(fs: LightningFS, entrypath: string) {
  try {
    await fs.promises.stat(entrypath);
    return true;
  } catch (err: any) {
    if (err.code === "ENOENT" || err.code === "ENOTDIR") {
      return false;
    } else {
      throw err;
    }
  }
}

// GitHub utils

export const currentCommit = ({ fs, dir }: { fs: LightningFS; dir: string }) =>
  resolveRef({ fs, dir, ref: "HEAD" });

// Flatpickr utils

// https://flatpickr.js.org/instance-methods-properties-elements/
export interface FlatpickrInput extends HTMLInputElement {
  _flatpickr: flatpickr.Instance;
}

// https://github.com/flatpickr/flatpickr/issues/1188
export const fixBlankInputOnSingleDate: flatpickr.Options.Hook = (
  selectedDates,
  dateStr,
  instance
) => {
  if (selectedDates.length === 1) {
    instance.setDate([selectedDates[0], selectedDates[0]]);
  }
};

type StateSetter = Dispatch<SetStateAction<string>>;

export const onDateRangeChange = (
  setFrom: StateSetter,
  setTo: StateSetter
): Hook => {
  return (dates, currDate) => {
    const [from, to] = currDate.split(" to ");
    setFrom(from);
    setTo(to ?? from); // in case they pick a single date
  };
};
