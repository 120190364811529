import { forceLogOut, retrieveUser } from "./storage";
import {
  AdminApproveCancellationRes,
  AdminApproveRefundRes,
  AdminEditDepartureNoteParams,
  AdminEditDepartureNoteReq,
  AdminEditDepartureNoteRes,
  AdminFindCancellationsReqSerialized,
  AdminFindCancellationsRes,
  AdminFindOtherRefundsReqSerialized,
  AdminFindOtherRefundsRes,
  AdminGetActivityDetailsParamsSerialized,
  AdminGetActivityDetailsRes,
  AdminGetActivityTotalsParams,
  AdminGetActivityTotalsRes,
  AdminGetInventoryParams,
  AdminGetInventoryRes,
  AdminGetInventoryTotalsParams,
  AdminGetInventoryTotalsRes,
  AdminMakeDepartureNoteParams,
  AdminMakeDepartureNoteReq,
  AdminMakeDepartureNoteRes,
  AdminUpdateDepartureParams,
  AdminUpdateDepartureReq,
  AdminUpdateDepartureRes,
  AdminUpdateDepartureStatusReq,
  AdminUpdateDepartureStatusRes,
  apiHelpers,
  JsongoDeparturePairsRes,
  JsongoDeparturePricesRes,
  JsongoLoginReq,
  JsongoLoginRes,
  JsongoLogoutRes,
} from "data-model";

export const API_URL = `https://${process.env.API_HOSTNAME}/v1/admin`;

export const WS_URL = `wss://${process.env.API_HOSTNAME}`;

export const DANNO_URL = `https://${process.env.DANNO_HOSTNAME}`;

const { json, jsonPost, jsonQuery, jsonPatch } = apiHelpers(API_URL, (err) => {
  if (err.status === 401 && retrieveUser()) {
    forceLogOut();
  }
});

export const logIn = (body: JsongoLoginReq): Promise<JsongoLoginRes> =>
  jsonPost("/login", body);

export const logOut = (): Promise<JsongoLogoutRes> => jsonPost("/logout", {});

export const getDeparturePrices = (): Promise<JsongoDeparturePricesRes> =>
  json("/departures/prices");

export const findCancellations = (
  params: AdminFindCancellationsReqSerialized,
  init?: RequestInit
): Promise<AdminFindCancellationsRes> =>
  jsonQuery("/cancellations", params, init);

export const approveCancellation = (
  cancellationId: number
): Promise<AdminApproveCancellationRes> =>
  jsonPost(`/cancellations/${cancellationId}/approve`, {});

export const findRefunds = (
  params: AdminFindOtherRefundsReqSerialized
): Promise<AdminFindOtherRefundsRes> => jsonQuery("/refunds", params);

export const approveRefund = (
  refundId: number
): Promise<AdminApproveRefundRes> =>
  jsonPost(`/refunds/${refundId}/approve`, {});

export const getActivityTotals = (
  params: AdminGetActivityTotalsParams
): Promise<AdminGetActivityTotalsRes> => jsonQuery("/activity/totals", params);

export const getActivityDetails = (
  params: AdminGetActivityDetailsParamsSerialized
): Promise<AdminGetActivityDetailsRes> =>
  jsonQuery("/activity/details", params);

export const getDepartureToCancelled = (
  series: string,
  year: number
): Promise<JsongoDeparturePairsRes> => json(`/departures/${series}/${year}`);

export const getInventory = (
  params: AdminGetInventoryParams
): Promise<AdminGetInventoryRes> => jsonQuery("/inventory", params);

export const makeDepartureNote = ({
  series,
  departedAt,
  body,
}: AdminMakeDepartureNoteParams &
  AdminMakeDepartureNoteReq): Promise<AdminMakeDepartureNoteRes> =>
  jsonPost(`/departures/${series}/${departedAt}/notes`, { body });

export const editDepartureNote = ({
  id,
  body,
}: AdminEditDepartureNoteParams &
  AdminEditDepartureNoteReq): Promise<AdminEditDepartureNoteRes> =>
  jsonPatch(`/departure-notes/${id}`, { body });

export const updateDepartureStatus = ({
  series,
  departedAt,
  status,
}: AdminMakeDepartureNoteParams &
  AdminUpdateDepartureStatusReq): Promise<AdminUpdateDepartureStatusRes> =>
  jsonPatch(`/departures/${series}/${departedAt}/status`, { status });

export const updateDeparture = ({
  series,
  departedAt,
  field,
  value,
}: AdminUpdateDepartureParams &
  AdminUpdateDepartureReq): Promise<AdminUpdateDepartureRes> =>
  jsonPatch(`/departures/${series}/${departedAt}`, { field, value });

export const getInventoryTotals = (
  params: AdminGetInventoryTotalsParams
): Promise<AdminGetInventoryTotalsRes> =>
  jsonQuery("/inventory/totals", params);
